import React from "react";

// Components
import TestimonialSlider from "../components/Herosections/TestimonialSlider";
import CTA from "../components/CTA/Cta";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import Fullwidthtitle from "../components/Herosections/Fullwidthtitle";
import Story from "../components/RetailshopSections/Story";
import FAQ from "../components/RetailshopSections/FAQ";
import FAQmobiles from "../components/RetailshopSections/FAQmobile";
import Why from "../components/RetailshopSections/Whychooseus";
import OrderLine from "../components/RetailshopSections/OrderLine";
import Subscription from "../components/RetailshopSections/Subscription";
import Business from "../components/RetailshopSections/Business";

// Images
import BG from "../images/cta-bg.png";
import Privacy from "../images/privacy.svg";
import Producticon from "../images/product-icon.svg";
import ProductTour from "../images/ProductTour.svg";
import CTABG from "../images/Flow1.json";

const RetailShop = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  // Props content
  const testimonials = [
    {
      quote:
        "Lorem Ipsum is simply dummy text of printing of typesetting industry. Lorem Ipsum is simply dummy Lorem Ipsum is simply dummy text of printing of typesetting industry. Lorem Ipsum is simply dummy",
      author: "Abdul Shakeer",
    },
    {
      quote:
        "Lorem Ipsum is simply dummy text of printing of typesetting industry. Lorem Ipsum is simply dummy Lorem Ipsum is simply dummy text of printing of typesetting industry. Lorem Ipsum is simply dummy",
      author: "Jane Smith",
    },
    {
      quote:
        "Lorem Ipsum is simply dummy text of printing of typesetting industry. Lorem Ipsum is simply dummy Lorem Ipsum is simply dummy text of printing of typesetting industry. Lorem Ipsum is simply dummy",
      author: "Michael Johnson",
    },
    // Add more testimonials as needed
  ];

  const tourItems = [
    {
      touricon: Producticon,
      subtitle: "Lorem Ipsum is simply dummy text of the printing.",
      tourcontent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trail" },
        { linkUrl: "#", text: "View more" },
      ],
    },
    // Add more items as needed
  ];

  const faqData = [
    {
      question: "01. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 1",
    },
    {
      question: "02. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 2",
    },
    {
      question: "03. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 3",
    },
    {
      question: "04. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 4",
    },
    {
      question: "05. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 5",
    },
    {
      question: "06. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 6",
    },
    {
      question: "07. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 7",
    },
    {
      question: "08. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 8",
    },
    {
      question: "09. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 9",
    },
  ];

  const faqData1 = [
    {
      question: "01. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 1",
    },
    {
      question: "02. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 2",
    },
    {
      question: "03. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 3",
    },
    {
      question: "04. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 4",
    },
    {
      question: "05. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 5",
    },
    {
      question: "06. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 6",
    },
    {
      question: "07. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 7",
    },
    {
      question: "08. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 8",
    },
    {
      question: "09. Lorem Ipsum is simply dummy text?",
      answer: "Answer to question 9",
    },
  ];
  const whycarddata = [
    {
      cardtitle: "LOREM IPSUM",
      cardcontent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
      dotColor: "#B0DAFA",
      borderColor: "#007CDA",
    },
    {
      cardtitle: "LOREM IPSUM",
      cardcontent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
      dotColor: "#FFF6C3",
      borderColor: "#FEDB03",
    },
    {
      cardtitle: "LOREM IPSUM",
      cardcontent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
      dotColor: "#B0FAD0",
      borderColor: "#00AA4A",
    },
    {
      cardtitle: "LOREM IPSUM",
      cardcontent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
      dotColor: "#FFC3B0",
      borderColor: "#E83800",
    },
  ];

  const topHeaderItems = [
    {
      buttons: [{ linkUrl: "#", text: "Start a free trail" }],
    },
  ];

  return (
    <>
      <Helmet>
        <title>RetailShop | The ERP Solution</title>
        <meta
          name="description"
          content="This is the SEO description for Tazk."
        />
        <meta name="keywords" content="landing page, seo, example" />
        <meta property="og:title" content="RetailShop | The ERP Solution" />
        <meta
          property="og:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
        <meta property="og:image" content="../images/favicon.ico" />
        <meta property="og:url" content="https://tazk.in/RetailShop" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="RetailShop | The ERP Solution" />
        <meta
          name="twitter:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
      </Helmet>
      <OrderLine
        title="Lorem Ipsum is simply dummy text."
        items={topHeaderItems}
      />

      <Why title="Why Choose Us?" whycard={whycarddata} />

      <Business title="WHAT WE’RE DOING FOR YOUR BUSSINESS" items={tourItems} />

      <Fullwidthtitle
        title="Your"
        spantitle="Privacy"
        PrivacyImg={Privacy}
        title2="Is Our 
        Responsibility"
        paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen ."
      />

      <Story title="Tour Title" items={tourItems} />

      <Subscription />

      {isDesktop ? (
        <FAQ faqData={faqData} />
      ) : (
        <FAQmobiles faqData={faqData1} />
      )}

      <TestimonialSlider
        testimonials={testimonials}
        slidesToShowDesktop={1}
        slidesToShowMobile={1}
        backgroundColor="#E6E6E9"
      />
    </>
  );
};

export default RetailShop;
