import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Import FontAwesome icons
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import ProductTour from "../images/ProductTour.svg";
import Producttourservices from "../images/Producttourservices.svg";
import Leads from "../images/leads1.svg";
import Asset from "../images/asset.svg";
import Distribution from "../images/distribution.svg";
import Payrol from "../images/payroll (2).png";
import Retailshop from "../images/retail-shop.svg";
import Services from "../images/services1.svg";

const Slider = () => {
  const slides = [
    {
      image: ProductTour,
      title: "Point of Sale",
      description:
        "Our Solution is specially designed with your Retail business in mind, user-friendly experience that simplifies transactions and enhances efficiency. With unique features like transferring stocks between your store, vendor schemes Dashboard, easy physical stock checks, GST compliance, repair services, scheme management pay-in/pay-out tracking, Receivable and payable management, and comprehensive inventory, accounting, payment reminders and customer data management, you gain the tools to drive your business growth. We ensure your operations run smoothly, so you can focus on delivering the best experience to your customers.",
    },
    {
      image: ProductTour,
      title: "Payroll",
      description:
        "Our trusted attendance and payroll software makes team management simple. It uses GPS-based mobile attendance for precise time tracking and offers fast payroll processing, flexible policies, and multi-shift management. With an easy interface, quick leave approvals, and smooth handling of salary advances and expense claims, our solution saves you time, minimizes errors, and gives you control from anywhere - so you can focus on growing your business.",
    },
    {
      image: Producttourservices,
      title: "Leads",
      description:
        "Our trusted leads management software helps you capture, organize, and follow up on leads easily. With its simple design and real-time tracking, you can prioritize the best opportunities and streamline your sales efforts. Boost your conversions and grow your business with our effective leads management solution.",
    },
    {
      image: Producttourservices,
      title: "Assets",
      description:
        "Our reliable asset management software makes it easy to track, manage, and maintain your assets. With user-friendly features and real-time updates, you can monitor asset performance, streamline maintenance, and ensure the best use of your assets. Increase efficiency and make informed decisions with our dependable asset management solution.",
    },
    {
      image: ProductTour,
      title: "Repair Services",
      description:
        "Our repair and service tool provides comprehensive control over all aspects of your repair business with a fully integrated, end-to-end workflow. This means that from the moment a repair request is received to the final delivery of the repaired item, every step is managed within a single, unified platform.",
    },
    {
      image: ProductTour,
      title: "Sales and Distribution",
      description:
        "Our comprehensive distribution app helps businesses expand. It strengthens your sales team, monitors payments, manages approvals, tracks vendor dues, and gives live delivery updates. With efficient inventory management and complete support for the distribution process, our app ensures your business thrives in today's market.",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const getLeftImage = (title) => {
    switch (title) {
      case "Point of Sale":
        return Retailshop;
      case "Payroll":
        return Payrol;
      case "Leads":
        return Leads;
      case "Assets":
        return Asset;
      case "Repair Services":
        return Services;
      case "Sales and Distribution":
        return Distribution;
      default:
        return ProductTour;
    }
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  // Function to handle "View More" click
  const handleViewMoreClick = () => {
    if (slides[currentSlide].title === "Payroll") {
      // Navigate to Payroll page if the current slide is "Payroll"
      navigate("/payroll", { replace: true });
      window.scrollTo(0, 0); // Scroll to top of the page
    }
  };

  // Function to handle "Start a Free Trial" click
  const handleStartFreeTrialClick = () => {
    if (slides[currentSlide].title === "Payroll") {
      // Redirect to the specific signup URL for Payroll
      window.location.href =
        "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4";
    }
  };

  return (
    <div className="home-container">
      <section
        id="section-slider"
        className="slider-section py-8 relative w-full"
      >
        <h2 className="text-3xl md:text-4xl lg:text-5xl uppercase text-center font-medium mb-[2rem]">
          Products Tour
        </h2>

        <div className="relative w-full">
          <div
            className="relative w-11/12 mx-auto grid grid-cols-2"
            style={{ height: "500px" }}
          >
            <div className="flex flex-row">
              <div className="w-1/4 flex justify-center items-start mt-4">
                <img
                  src={getLeftImage(slides[currentSlide].title)}
                  alt={`Slide ${currentSlide + 1}`}
                  className="w-full h-auto object-contain transition-all duration-500"
                />
              </div>

              <div className="w-3/4 flex flex-col justify-center ml-4">
                <h3 className="text-2xl font-medium mb-2">
                  {slides[currentSlide].title}
                </h3>
                <p className="text-base mb-4">
                  {slides[currentSlide].description}
                </p>

                <div className="flex gap-4 mt-4 flex-wrap">
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition"
                    onClick={handleStartFreeTrialClick}
                    style={{ marginBottom: "10px" }} // Responsive margin
                  >
                    Start a Free Trial
                  </button>
                  <button
                    className="bg-white text-blue-500 py-2 px-4 rounded-full border border-blue-500 hover:bg-gray-200 transition"
                    onClick={handleViewMoreClick}
                    style={{ marginBottom: "10px" }} // Responsive margin
                  >
                    View More
                  </button>
                </div>
              </div>
            </div>

            <div className="overflow-hidden">
              <img
                src={slides[currentSlide].image}
                alt={`Slide ${currentSlide + 1}`}
                className="w-full h-auto object-contain transition-all duration-500"
              />
            </div>
          </div>

          {/* Arrow Buttons */}
          <div
            onClick={prevSlide}
            className="absolute left-4 cursor-pointer bg-white text-black border border-gray-300 p-3 rounded-full hover:bg-gray-100 transition left-arrow"
            style={{
              top: "35%", // Moved up by decreasing the top value
              transform: "translateY(-50%)",
              borderRadius: "50%", // Fully rounded button
              borderColor: "rgb(245, 245, 245)", // Border color
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional: adds a subtle shadow for effect
            }}
          >
            <FaArrowLeft size={20} style={{ color: "black" }} />{" "}
            {/* Left Arrow Icon in black */}
          </div>

          <div
            onClick={nextSlide}
            className="absolute right-4 cursor-pointer bg-white text-black border border-gray-300 p-3 rounded-full hover:bg-gray-100 transition right-arrow"
            style={{
              top: "35%", // Moved up by decreasing the top value
              transform: "translateY(-50%)",
              borderRadius: "50%", // Fully rounded button
              borderColor: "rgb(245, 245, 245)", // Border color
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional: adds a subtle shadow for effect
            }}
          >
            <FaArrowRight size={20} style={{ color: "black" }} />{" "}
            {/* Right Arrow Icon in black */}
          </div>

          {/* Dots Indicator */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full cursor-pointer transition ${
                  currentSlide === index ? "bg-blue-500" : "bg-gray-300"
                }`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Slider;
