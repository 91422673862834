import React from "react";
import { Link } from "react-router-dom";

// images
import Leads from "../images/leads1.svg";
import Asset from "../images/asset.svg";
import Distribution from "../images/distribution.svg";
import Payrol from "../images/payroll.svg";
import Retailshop from "../images/retail-shop.svg";
import Services from "../images/services1.svg";

const MegaMenu = React.forwardRef((props, ref) => {
  const features = [
    {
      image: Retailshop,
      label: "Point of Sale ",
      link: "#",
      description:
        "Power Your Growth with Easy, Efficient POS Solutions Built for Your Retail Chain!",
    },
    {
      image: Payrol,
      label: "Payroll",
      link: "/payroll",
      description: "Simplify Your Workforce Management",
    },
    {
      image: Leads,
      label: "Leads",
      link: "#",
      description: "Supercharge Your Sales with Our Leads Management Software",
    },
    {
      image: Asset,
      label: "Asset",
      link: "#",
      description:
        "Manage Your Assets with Our Effective Asset Management Software",
    },
    {
      image: Services,
      label: "Repair Service",
      link: "#",
      description:
        "Top-Quality Repairs, 100% Customer Satisfaction, Zero Manual Effort",
    },
    {
      image: Distribution,
      label: "Sales and Distribution",
      link: "#",
      description:
        "Empower Your Sales, Streamline Your Distribution, Thrive in the Market.",
    },
  ];

  return (
    <div
      ref={ref}
      className="absolute left-0 w-full bg-white shadow-lg p-6 mt-2 top-14"
    >
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
        {features.map((feature, index) => (
          <Link to={feature.link} key={index} className="block">
            <div className="flex items-center bg-white p-4 w-full h-full">
              <img
                src={feature.image}
                alt={feature.label}
                className="w-12 h-12 mr-4 object-cover"
              />
              <div className="block">
                <p className="text-headerText font-medium uppercase">
                  {feature.label}
                </p>
                <p className="text-paragraphText font-normal">
                  {feature.description}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
});

export default MegaMenu;
