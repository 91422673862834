// src/components/Headermain.jsx
import React, { useState, useEffect } from "react";
import payrolllogo from "../images/payroll-logo1.svg";
import { Link } from "react-router-dom";
import getServerConfig from "../http-common";

const PayrollHeader = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleStartFreeTrialId4 = () => {
    const redirectUrl = getServerConfig('Payroll', 4); 
    console.log('Opening URL:', redirectUrl);
    // window.location.href = redirectUrl; // Redirect the user
    window.open(redirectUrl, '_blank');
  };

  const handleStartFreeTrialId3 = () => {
    const redirectUrl = getServerConfig('Payroll', 3); 
    console.log('Opening URL:', redirectUrl);
    // window.location.href = redirectUrl; // Redirect the user
    window.open(redirectUrl, '_blank');
  };

  return (
    <header
      className={`sticky top-0 z-20 transition duration-300 ease-in-out ${
        isSticky ? "bg-white shadow-md" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-4 md:px-8 py-4 z-10 flex justify-between items-center relative">
        <Link to="/payroll">
          <img src={payrolllogo} alt="Tazk" className="w-4/5 md:w-auto" />
        </Link>
        <div className="gap-2 flex">
          {/* Update SIGN-IN button to navigate to external URL */}
          <button className="hidden md:block px-4 py-2 text-paragraphText hover:text-GreenText rounded-full"
            onClick={handleStartFreeTrialId3}
          >
            SIGN-IN
          </button>
          <button className="px-4 py-2 text-GreenText hover:bg-GreenText hover:text-white rounded-lg border-2 md:border border-GreenText"
          onClick={handleStartFreeTrialId4}>
            SIGN-UP
          </button>
        </div>
      </div>
    </header>
  );
};

export default PayrollHeader;
