import React from "react";
import projects from "../../images/projects.jpg"
import chat from "../../images/chats.png"

function Mobilebenifits({
  title,
  subtitle,
  content,
  subtitle1,
  content1,
  benifit,
  cardtitle1,
  cardcontent1,
  cardtitle2,
  cardcontent2,
  cardtitle3,
  cardcontent3,
  cardtitle4,
  cardcontent4,
  cardtitle5,
  cardcontent5,
}) {
  return (
    <section className="py-4 md:py-16 container mx-auto font-urbanist">
  <div className="mx-4 md:mx-8 z-10">
    <div className="mb-10 text-center">
      <h4 className="text-black text-3xl md:text-4xl lg:text-4xl font-semibold uppercase">
        {title}
      </h4> 
      <p className="font-urbanist mt-6 md:mt-4 text-xl md:text-2xl font-semibold text-[rgb(0,170,74)]">
        {subtitle}
      </p>
      <p className="mt-6 md:mt-4 w-[90%] md:w-[70%] mx-auto font-urbanist text-base md:text-lg text-center">
        {content}
      </p>
    </div>
    
    <div className="flex flex-col gap-6 md:gap-4">
      {/* First Row */}
      <div className="flex items-center justify-center w-full md:w-2/3">
          <img
            src={projects}
            alt="projects"
            className="object-cover w-full h-[250px] md:h-[400px] rounded-lg"
          />
        </div>
      <div className="flex flex-col md:flex-row gap-6 md:gap-4">
        {/* 1st Column (Card Content 1) */}
        <div className="bg-white card-shadow p-6 md:p-8 rounded-3xl w-full md:w-1/3">
          <p className="text-headerText text-xl md:text-2xl font-semibold">
            {cardtitle1}
          </p>
          <p className="text-base md:text-lg">{cardcontent1}</p>
        </div>
      </div>

      {/* Second Row */}
      <div className="flex flex-col md:flex-row gap-6 md:gap-4">
        {/* 1st Column (Card Content 2) */}
        <div className="bg-white card-shadow p-6 md:p-8 rounded-3xl w-full md:w-1/3">
          <p className="text-headerText text-xl md:text-2xl font-semibold">
            {cardtitle2}
          </p>
          <p className="text-base md:text-lg">{cardcontent2}</p>
        </div>

        {/* 2nd Column (Card Content 3) */}
        <div className="bg-white card-shadow p-6 md:p-8 rounded-3xl w-full md:w-1/3">
          <p className="text-headerText text-xl md:text-2xl font-semibold">
            {cardtitle3}
          </p>
          <p className="text-base md:text-lg">{cardcontent3}</p>
        </div>

        {/* 3rd Column (Card Content 4) */}
        <div className="bg-white card-shadow p-6 md:p-8 rounded-3xl w-full md:w-1/3">
          <p className="text-headerText text-xl md:text-2xl font-semibold">
            {cardtitle4}
          </p>
          <p className="text-base md:text-lg">{cardcontent4}</p>
        </div>
      </div>
    </div>

    <p className="font-urbanist mt-6 md:mt-10 text-xl md:text-2xl font-semibold text-[rgb(0,170,74)] text-center">
      {subtitle1}
    </p>
    <p className="mt-6 md:mt-4 w-[90%] md:w-[70%] mx-auto font-urbanist text-base md:text-lg text-center">
      {content1}
    </p>
    <div className="flex justify-center items-center">
      <img src={chat} className="mx-auto mt-6 md:mt-10 w-full md:w-[400px] h-auto" alt="Chat Image" />
    </div>
  </div>
</section>
  );
}

export default Mobilebenifits;
