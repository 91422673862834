// http-common.js
const serverConfig  =  {

    local: {
      host: 'localhost',
      base_url: 'http://localhost:4000',
      signup_host_url: 'http://localhost:3001',
      signin_host_url: 'http://localhost:3000',
      web_socket_url: 'ws://localhost:4000',
      socket_io_url: 'http://localhost:4100',
      title: 'Salesplay',
    },
    dev: {
      host: 'd.tazk.in',
      base_url: 'https://devserver.tazk.in',
      signup_host_url: 'https://signupdev.tazk.in',
      signin_host_url: 'https://dev.tazk.in',
      web_socket_url: 'wss://devserver.tazk.in',
      socket_io_url: 'https://devserver.tazk.in',
      title: 'Tazk',
    },
    aws: {
      host: 'tazk.in',
      base_url: 'https://erpserver.tazk.in',
      signup_host_url: 'https://signup.tazk.in',
      signin_host_url: 'https://erp.tazk.in',
      web_socket_url: 'wss://erpserver.tazk.in',
      socket_io_url: 'https://erpserver.tazk.in',
      title: 'Tazk',
    },
  };

  const getServerConfig = (type = 'Payroll', id = 1) => {
    const hostname = window.location.hostname;
    const config =
      hostname === serverConfig.aws.host
        ? serverConfig.aws
        : hostname === serverConfig.dev.host
        ? serverConfig.dev
        : serverConfig.local;
  
    if (type === 'getHost') {
      return config.base_url;
    }
  
    if (type === 'Payroll') {
      if (id === 1 || id === 4) {
        return `${config.signup_host_url}/signUpVerification?type=${type}&id=${id}`;
      } else if (id === 3) {
        return config.signin_host_url;
      }
      return false;
    }
  
    return false; 
  };
  
  export default getServerConfig;