import React, { useEffect, useRef } from "react";
import payroll from "../../images/payroll.png";
import retailchainshop from "../../images/01Retail Chain Shop.svg";
import manufacturing from "../../images/02Manufacturing.svg";
import itandsupport from "../../images/03IT and Support.svg";
import hotels from "../../images/04Hotels and Restaurant.svg";
import sales from "../../images/05Slaes and distribution.svg";
import schools from "../../images/06Schools and Collage.svg";
import construction from "../../images/07construction.svg";
import logistics from "../../images/08Logistics.svg";
import servicesupport from "../../images/09Service Support.svg";
import sme from "../../images/10SME's.svg";
import school from "../../images/Schools and Collages (2).svg";

const Story = () => {
  const storyRef = useRef(null);

  useEffect(() => {
    // Check if the URL matches the industries path
    if (window.location.pathname === "/payroll/industries") {
      // Use a timeout to ensure the DOM is fully loaded before scrolling
      setTimeout(() => {
        if (storyRef.current) {
          // Scroll to the top of the story section
          window.scrollTo({
            top: storyRef.current.getBoundingClientRect().top + window.scrollY,
            behavior: "smooth",
          });
        }
      }, 0); // Adjust the timeout if necessary
    }
  }, []);

  return (
    <section
      ref={storyRef}
      className="storyMobile container m-auto mt-4 md:my-20 font-urbanist"
    >
      <div className="md:mx-12 lg:mx-12 xs:mx-8 sm:mx-8 bg-[rgb(240,240,240)] text-black justify-between gap-4 rounded-[38px] py-10 px-8">
        {/* Heading */}
        <div className="flex justify-center xl:w-[50%] mx-auto">
          <h1 className="text-xl md:text-4xl lg:text-[37px] font-semibold uppercase leading-[32px] md:leading-[62px] mb-1 text-center whitespace-normal">
            Who Can Maximize Their Success with Tazk Payroll?
          </h1>
        </div>

        {/* Rows of Columns */}
        <div className="mt-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 text-center text-black">
          {/* First Row */}
          <div className="flex flex-col items-center">
            <img
              src={retailchainshop}
              alt="Icon 1"
              className="w-16 h-16 mb-2"
            />
            <p className="text-sm md:text-lg">Retail Chain Shops</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={manufacturing} alt="Icon 2" className="w-16 h-16 mb-2" />
            <p className="text-sm md:text-lg">Manufacturing</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={itandsupport} alt="Icon 3" className="w-16 h-16 mb-2" />
            <p className="text-sm md:text-lg">IT and Support</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={hotels} alt="Icon 4" className="w-16 h-16 mb-2" />
            <p className="text-sm md:text-lg">Hotels & Restaurants</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={sales} alt="Icon 5" className="w-16 h-16 mb-2" />
            <p className="text-sm md:text-lg">Sales and Distributions</p>
          </div>

          {/* Second Row */}
          <div className="flex flex-col items-center">
            <img src={school} alt="Icon 6" className="w-16 h-16 mb-2" />
            <p className="text-sm md:text-lg">Schools and Colleges</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={construction} alt="Icon 7" className="w-16 h-16 mb-2" />
            <p className="text-sm md:text-lg">Construction</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={logistics} alt="Icon 8" className="w-16 h-16 mb-2" />
            <p className="text-sm md:text-lg">Logistics</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={servicesupport} alt="Icon 9" className="w-16 h-16 mb-2" />
            <p className="text-sm md:text-lg">Service Support</p>
          </div>
          <div className="flex flex-col items-center">
            <img src={sme} alt="Icon 10" className="w-16 h-16 mb-2" />
            <p className="text-sm md:text-lg">SME’s</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Story;
