import React from "react";
import footerlogo from "../images/footerlogo.svg";
import Leads from "../images/leads1.svg";
import Asset from "../images/asset.svg";
import Distribution from "../images/distribution.svg";
import Payrol from "../images/payroll.svg";
import Retail from "../images/retail-chain.svg";
import Retailshop from "../images/retail-shop.svg";
import Services from "../images/services1.svg";

import fb from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import x from "../images/x.svg";
import linkedin from "../images/linkdin.svg";

const Footer = () => {
  const companyDetails = {
    title: "Company",
    address:
      "8/65, 1st Floor, Radhakrishnan St, Velachery, Chennai, TamilNadu 600042",
    mobile: "9500000404",
    email: "contact@tazk.in",
  };

  const socialLinks = [
    { src: fb, alt: "Facebook", href: "#" },
    { src: instagram, alt: "Instagram", href: "#" },
    { src: x, alt: "X", href: "#" },
    { src: linkedin, alt: "LinkedIn", href: "#" },
  ];

  const applinks = [
    { title: "Point of Sale", href: "#" },
    { title: "Payroll", href: "/payroll" },
    { title: "Leads", href: "#" },
    { title: "Asset", href: "#" },
    { title: "Repair Services", href: "#" },
    { title: "Sales and Distribution", href: "#" },
    // { title: "Retail Chain", href: "#" },
  ];

  // Function to scroll to top when the logo is clicked
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scroll to top
  };

  return (
    <>
      <footer className="bg-[#F5F5F5] text-white">
        <div className="container mx-auto py-8 px-[30px]">
          {" "}
          {/* Set left and right padding to 30px */}
          <div className="grid grid-cols-1 md:grid-cols-[20%_1fr_1fr_1fr] gap-8 text-left">
            <div className="col-span-1 flex justify-center items-start">
              {/* Add onClick event to scroll to top */}
              <img
                src={footerlogo}
                alt="Logo"
                className="w-3/5 md:w-8/12 cursor-pointer"
                onClick={scrollToTop} // Trigger scroll to top
              />
            </div>
            <div>
              <h3 className="text-xl font-medium text-[#262626] mb-6">
                {companyDetails.title}
              </h3>

              {/* Address */}
              <p className="text-paragraphText mb-3 flex items-center">
                <a
                  href="https://www.google.com/maps/search/?api=1&query=8/65,+1st+Floor,+Radhakrishnan+St,+Velachery,+Chennai,+TamilNadu+600042"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <i className="fas fa-map-marker-alt mr-2 mt-[-4px]"></i>
                  {companyDetails.address}
                </a>
              </p>

              {/* Mobile */}
              <p className="text-paragraphText mb-3 flex items-center">
                <a
                  href={`tel:${companyDetails.mobile}`}
                  className="flex items-center"
                >
                  <i className="fas fa-phone-alt mr-2 transform rotate-90"></i>
                  {companyDetails.mobile}
                </a>
              </p>

              {/* Email */}
              <p className="text-paragraphText mb-3 flex items-center">
                <a
                  href={`mailto:${companyDetails.email}`}
                  className="flex items-center"
                >
                  <i className="fas fa-envelope mr-2"></i>
                  {companyDetails.email}
                </a>
              </p>
            </div>

            <div className="col-span-1">
              <h3 className="text-xl font-medium text-[#262626] mb-3">
                Our Application
              </h3>
              <div>
                {applinks.map((link, index) => (
                  <p key={index} className="text-paragraphText mb-3">
                    <a
                      href={link.href}
                      className="text-paragraphText hover:underline"
                    >
                      {link.title}
                    </a>
                  </p>
                ))}
              </div>
            </div>
            <div className="col-span-1 flex flex-col">
              <h3 className="text-xl font-medium text-[#262626] mb-3">
                Follow Us
              </h3>
              <div className="flex space-x-4 justify-center md:justify-start">
                {" "}
                {/* Center icons */}
                {socialLinks.map((link, index) => (
                  <a key={index} href={link.href} className="flex-shrink-0">
                    <img
                      src={link.src}
                      alt={link.alt}
                      className="w-6 h-6" // Adjust size as needed
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="px-2 md:px-8 py-4">
            <div className="container mx-auto text-center">
              <p className="text-paragraphText">
                <a
                  href="/terms-and-conditions"
                  className="text-paragraphText hover:text-primary underline"
                >
                  Terms and Conditions
                </a>
                {" | "}
                <a
                  href="/privacy-policy"
                  className="text-paragraphText hover:text-primary underline"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* New row for Terms and Conditions and Privacy Policy links */}

        <div className="bg-[#D9D9D9] px-2 md:px-8">
          <div className="text-center container mx-auto">
            <p className="text-paragraphText py-2">
              © 2024{" "}
              <a
                href="https://tazk.in/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-paragraphText hover:text-primary underline"
              >
                TAZK
              </a>{" "}
              All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
