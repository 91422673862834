// src/components/Footer.js
import React from "react";
import payrollfooter from "../images/payroll-logo1.svg";

import fb from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import x from "../images/x.svg";
import linkedin from "../images/linkdin.svg";
import getServerConfig from "../http-common";

const PayrollFooter = () => {
  const companyDetails = {
    title: "Company",
    address:
      "8/65, 1st Floor, Radhakrishnan St, Velachery, Chennai, TamilNadu 600042",
    mobile: "9500000404",
    email: "contact@tazk.in",
  };

  const socialLinks = [
    { src: fb, alt: "Facebook", href: "#" },
    { src: instagram, alt: "Instagram", href: "#" },
    { src: x, alt: "X", href: "#" },
    { src: linkedin, alt: "LinkedIn", href: "#" },
  ];

  const handleStartFreeTrialId4 = () => {
    const redirectUrl = getServerConfig('Payroll', 4); 
    console.log('Opening URL:', redirectUrl);
    // window.location.href = redirectUrl; // Redirect the user
    window.open(redirectUrl, '_blank');
  };

  const quickLinks = [
    // { name: "Sign-up", href: "https://signup.tazk.in/" },
    {
      name: "Start a Free Trail",
      // href: "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4",
      onClick: handleStartFreeTrialId4,
    },
  ];

  const payrollfootercontent =
    "Effortless Attendance and Payroll Management, Anytime, Anywhere.";

  const copyrightText = [
    "© 2021 TAZK. Copyright © 2024 TAZK. All Rights Reserved.",
  ];

  return (
    <footer className="bg-[#F5F5F5] text-white">
      <div className="container mx-auto py-8 md:py-12 px-6 md:px-8">
        <div className="grid grid-cols-1 gap-8 text-left md:grid-cols-[30%_30%_20%_20%]">
          <div>
            <a href="#top">
              <img src={payrollfooter} alt="Logo" className="mx-0 mb-4" />
            </a>
            <p className="text-paragraphText mb-3">{payrollfootercontent}</p>
          </div>
          <div>
            <h3 className="text-xl font-medium text-[#262626] mb-6">
              {companyDetails.title}
            </h3>

            {/* Address */}
            <p className="text-paragraphText mb-3 flex items-center">
              <a
                href="https://www.google.com/maps/search/?api=1&query=8/65,+1st+Floor,+Radhakrishnan+St,+Velachery,+Chennai,+TamilNadu+600042"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <i className="fas fa-map-marker-alt mr-2 mt-[-4px]"></i>
                {companyDetails.address}
              </a>
            </p>

            {/* Mobile */}
            <p className="text-paragraphText mb-3 flex items-center">
              <a
                href={`tel:${companyDetails.mobile}`}
                className="flex items-center"
              >
                <i className="fas fa-phone-alt mr-2 transform rotate-90"></i>
                {companyDetails.mobile}
              </a>
            </p>

            {/* Email */}
            <p className="text-paragraphText mb-3 flex items-center">
              <a
                href={`mailto:${companyDetails.email}`}
                className="flex items-center"
              >
                <i className="fas fa-envelope mr-2"></i>
                {companyDetails.email}
              </a>
            </p>
          </div>

          <div>
            <h3 className="text-xl font-medium text-[#262626] mb-6">
              Get Started Now
            </h3>
            {quickLinks.map((link, index) => (
              <p key={index} className="mb-3">
                {link.onClick ? (
                   // eslint-disable-next-line jsx-a11y/anchor-is-valid
                   <a
                   onClick={(e) => {
                     e.preventDefault(); // Prevent default link behavior
                     link.onClick(); // Call the custom handler
                   }}
                   className="text-paragraphText"
                   style={{ cursor: 'pointer' }} // Style the anchor like a link
                 >
                   {link.name}
                 </a>
                ) : (
                  <a href={link.href} className="text-paragraphText">
                    {link.name}
                  </a>
                )}
              </p>
            ))}
          </div>
          <div className="flex flex-col">
            <h3 className="text-xl font-medium text-[#262626] mb-3">
              Follow Us
            </h3>
            <div className="flex space-x-4">
              {socialLinks.map((link, index) => (
                <a key={index} href={link.href} className="flex-shrink-0">
                  <img
                    src={link.src}
                    alt={link.alt}
                    className="w-6 h-6" // Adjust size as needed
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="px-2 md:px-8 py-4">
          <div className="container mx-auto text-center">
            <p className="text-paragraphText">
              <a
                href="/terms-and-conditions"
                className="text-paragraphText hover:text-primary underline"
              >
                Terms and Conditions
              </a>
              {" | "}
              <a
                href="/privacy-policy"
                className="text-paragraphText hover:text-primary underline"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className=" bg-[#D9D9D9] px-2 md:px-8">
        <div className="text-center container mx-auto">
          <p className="text-paragraphText py-2">
            © 2021{" "}
            <a
              href="https://tazk.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-paragraphText hover:text-primary underline"
            >
              TAZK
            </a>
            {/* . Copyright © 2024&nbsp;
            <a
              href="https://tazk.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-paragraphText hover:text-primary underline"
            >
              TAZK
            </a> */}
            . All Rights Reserved.
            {/* <a
              href="http://svaantech.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-paragraphText hover:text-primary underline"
            >
              SVaaN.
            </a> */}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default PayrollFooter;
